<template>
  <div class="kvass-image-text" v-if="value.length">
    <div
      v-for="(item, index) in value"
      :key="index"
      class="kvass-image-text__item"
      :class="[
        `kvass-image-text__item-theme--${item.theme || 'none'}`,

        {
          'kvass-image-text__item--has-content': Boolean(item.description),
          'kvass-image-text__item--text-over': (item['image-settings'] || []).includes('text-over'),
        },
      ]"
    >
      <kvass-img-comparison-slider
        v-if="item.comparison"
        :first-image="imageUrl(item.image[0])"
        :second-image="imageUrl(item.image[1])"
      ></kvass-img-comparison-slider>

      <component
        v-else-if="getImage(item)"
        :is="item.Component"
        class="kvass-image-text__image"
        :class="[
          {
            'kvass-image-text__image--frame': (item['image-settings'] || []).includes('frame'),
            'kvass-image-text__image--round-edges': (item['image-settings'] || []).includes(
              'round-edges',
            ),
          },
          `kvass-image-text__image-type--${item.type}`,
        ]"
        loop
        :value="{
          url: getImage(item).url,
          name: item.title,
          description: getImage(item).description,
        }"
        :display-thumbnail="false"
        v-bind="$attrs"
      />

      <div
        class="kvass-image-text__content"
        :class="{
          'kvass-image-text__content--text-over': (item['image-settings'] || []).includes(
            'text-over',
          ),
        }"
      >
        <div class="kvass-image-text__kicker">{{ item.kicker }}</div>
        <div class="kvass-image-text__description" v-html="item.description" />
      </div>
    </div>
  </div>
</template>

<script>
import { Types } from '@kvass/media-render'

export default {
  props: {
    data: Object,
  },
  computed: {
    value() {
      return (this.$path('content', this.data) || []).map(item => {
        return {
          ...item,
          type: this.getType(this.$path('image.type', item)),
          Component: this.getComponent(this.$path('image.type', item)),
        }
      })
    },
  },
  methods: {
    getImage(item) {
      if (!item.image) return
      return item.image instanceof Array ? item.image[0] : item.image
    },
    imageUrl(image) {
      if (!image) return
      return `${image.url}${image.description ? `,${image.description}` : ''}`
    },

    getType(type) {
      switch (type) {
        case 'youtube':
          return 'youtube'
        case 'vimeo':
          return 'vimeo'
        default:
          return 'image'
      }
    },

    getComponent(type) {
      switch (type) {
        case 'youtube':
          return Types.Youtube
        case 'vimeo':
          return Types.Vimeo
        default:
          return Types.Image
      }
    },
  },
}
</script>

<style lang="scss">
$textOverMargin: 11rem;

.kpb-section--width-full.section-image-text {
  $maxWidth: 800px;

  .kvass-image-text__item:not(.kvass-image-text__item--text-over) {
    .kvass-image-text__content {
      max-width: $maxWidth;
    }
  }

  .kvass-image-text__item--text-over {
    .kvass-image-text__content {
      max-width: calc(#{$maxWidth} + #{$textOverMargin});
    }
  }
}

.kvass-image-text {
  column-gap: 4rem;
  padding: 0;

  &__kicker {
    text-transform: uppercase;
    font-weight: 500;
    opacity: 0.8;
    font-size: 0.9rem;
  }

  &__item {
    $space: 20rem;
    display: grid;

    grid-gap: 4rem;
    align-items: center;
    margin: 0 !important;
    padding: 6rem;

    @include respond-below('tablet') {
      grid-template-columns: 1fr;
      grid-gap: 1rem;
      padding: 2rem 1rem 2rem 1rem;
    }

    @include respond-above('tablet') {
      &:nth-child(odd) {
        direction: rtl;

        .kvass-image-text__content {
          text-align: right;
          justify-content: end;

          &--text-over {
            margin: 4rem calc(-#{$textOverMargin}) 1.5rem auto;
          }
        }
      }

      &:nth-child(even) {
        direction: initial !important;

        .kvass-image-text__content {
          text-align: right;
          justify-content: start;

          &--text-over {
            margin: 4rem auto 1.5rem calc(-#{$textOverMargin});
          }
        }
      }
    }

    &--has-content {
      @include respond-above('tablet') {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    &:nth-child(1).kvass-image-text__item-theme--none {
      padding-top: 0;
    }

    * {
      direction: ltr;
      text-align: left;
    }

    &-theme {
      &--primary {
        background-color: var(--primary);
        color: var(--primary-contrast);

        .kvass-image-text__image {
          &--frame {
            img {
              border: 3px solid var(--secondary);
            }
          }
        }
      }

      &--secondary {
        background-color: var(--secondary);
        color: var(--secondary-contrast);

        .kvass-image-text__image {
          &--frame {
            img {
              border: 3px solid var(--primary);
            }
          }
        }
      }

      &--none {
        .kvass-image-text__image {
          &--frame {
            img {
              border: 3px solid var(--primary);
            }
          }
        }
      }
    }
  }

  &__image {
    position: relative;
    width: 100%;
    height: 100%;

    &.kvass-media-render-vimeo,
    &.kvass-media-render-youtube {
      background-color: inherit;
    }

    &-type {
      &--vimeo,
      &--youtube {
        aspect-ratio: 16/9;
      }
    }

    iframe {
      height: 100%;
      width: 100%;
    }

    &--frame {
      iframe,
      img {
        border: 3px solid white;
        padding: 0.4rem;
      }
    }

    &--round-edges {
      $radius: 3rem;

      img {
        border-radius: $radius;
      }

      .kvass-media-render-image__caption {
        border-radius: $radius;
      }

      @include respond-below('tablet') {
        img {
          border-radius: calc(#{$radius} - 1rem);
        }

        .kvass-media-render-image__caption {
          border-radius: calc(#{$radius} - 1rem);
        }
      }
    }

    @include respond-below('tablet') {
      padding: 2rem 1rem 0;

      &:not(.kvass-image-text__image--frame),
      &:not(.kvass-image-text__image--round-edges) {
        padding: 0;
      }
    }
  }

  &__content {
    &--text-over {
      background: white;
      padding: 2rem;
      position: relative;
      color: initial;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }
  }

  @include respond-above('tablet') {
    .kvass-media-render-image {
      margin: auto 0;
      max-height: 60vh;
      min-height: 500px;
    }
  }

  .kvass-media-render-image__caption {
    text-align: center;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: end;
    padding: 0.8em 2rem;
  }
}
</style>
