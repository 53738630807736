<template>
  <Loader :value="promises.fetch" theme="default">
    <div class="project-page">
      <Blocks ref="blocks" :blocks="config" :custom-components="customComponents" />
    </div>
  </Loader>
</template>

<script>
import CustomComponents from '@/components/Custom'
import ProjectComponents from '@/components/Project'
import { mapActions, mapState } from 'vuex'

import BrowserApiMixin from '@/mixins/browser-api'
import { GetCustomField } from '@/utils'
import { Blocks } from '@kvass/pagebuilder'
import { animate, inView, stagger } from 'motion'
import Config from '../config/project'

export default {
  mixins: [BrowserApiMixin('project')],

  computed: {
    ...mapState('Project', ['promises', 'item']),
    config() {
      return Config.call(this)
    },
    customComponents() {
      return {
        ...CustomComponents,
        ...ProjectComponents,
      }
    },
  },

  methods: {
    ...mapActions('Project', ['fetch']),
    GetCustomField,
    async initAnimation() {
      animate(
        '.hero__content ',
        { opacity: 1, transform: 'none' },
        { duration: 0.8, delay: stagger(0.3) },
      )

      inView('.section-info-image', ({ target }) => {
        animate(target, { opacity: 1, transform: 'none' }, { duration: 0.8 })
      })

      inView(
        '.section-info__content',
        ({ target }) => {
          animate(target, { opacity: 1, transform: 'none' }, { duration: 0.8 })
        },
        { margin: '0px 0px -200px 0px' },
      )

      inView('.kvass-image-text__item', ({ target }) => {
        animate(target, { opacity: 1, transform: 'none' }, { duration: 0.8 })
      })
    },
  },
  created() {
    this.fetch()
  },
  mounted() {
    this.promises.fetch.then(() => this.$nextTick(() => this.initAnimation()))
  },

  metaInfo() {
    return {
      title: this.$t('home'),
    }
  },

  components: {
    ...ProjectComponents,
    Blocks,
  },
}
</script>

<style lang="scss">
.nav--project-expanded + .elder-loader {
  .cta-banner {
    display: none;
  }
}

.project-page {
  @for $i from 1 through 15 {
    & > *:nth-child(#{$i}) {
      order: $i * 10;
    }
  }

  .kvass-image-text__item {
    opacity: 0;
  }

  .hero {
    &__slider {
 
      $height: 80vh;
  


      position: relative;
      max-height: $height;
      width: 100%;
      height: $height;
      background-color: css-alpha('primary', 0.1);

      img {
        object-position: center;
      }

      iframe {
        min-height: $height;
      }

      display: flex;
      flex-direction: column;

      @include respond-below('tablet') {
        height: $height;
      }

      @include respond-below('phone') {
        iframe {
          min-height: $height;
        }
      }
    }

    &__stats {
      position: absolute;
      bottom: 2rem;
      left: 50%;
      translate: -50%;
      color: white;
      text-shadow: 0 2px 8px rgba(black, .5);
      font-size: 1.5rem;
      background-color: rgba(black, .2);
      padding: .75rem 1.5rem;
      border-radius: .75rem;
      text-align: center;
    }
  }

  .hero__content {
    position: absolute;
    color: white;
    font-size: clamp(0.9rem, 2vw, 1.1rem);
    z-index: get-layer('cover-overlay');

    & > * {
      padding: 0rem 1rem;
    }

    h1 {
      margin-bottom: 0.5em;
      line-height: 1.2;
      font-size: 2.5em;

      @include respond-below('phone') {
        font-size: 1.8em;
      }
    }

    p {
      margin-top: 0;
    }

    .elder-button__label {
      font-size: 1.1rem;
      padding: 0.9rem 1.25rem;

      @include respond-below('phone') {
        padding: 0.5rem 1rem;
        font-size: 0.8rem;
      }
    }

    .elder-button--icon-right .elder-button__icon {
      padding: 0.9rem 1.25rem 0.9rem 0;
      font-size: 1.1rem;

      @include respond-below('phone') {
        padding: 0.5rem 1rem;
        font-size: 0.8rem;
      }
    }

    a {
      margin: 0 0.5rem;

      &[data-cta='primary'] {
        background: white;
        color: black;
        font-weight: 500;

        &:hover {
          background: rgba(white, 0.9);
        }
      }

      &[data-cta='secondary'] {
        font-weight: 500;
        background: transparent;
        color: white;
        border: 1px solid white;

        &:hover {
          background: rgba(black, 0.2);
        }
      }
    }

    &-style {
      &--overlay {
        bottom: initial;
        right: initial;
        top: 0;
        left: 0;
        height: 100%;
        opacity: 0;
        background-color: rgba(black, 0.3);

        &.hero__content-placement--left {
          .kpb-text {
            margin-left: 3rem;

            @include respond-below('phone') {
              margin-left: 1rem;
            }
          }
        }
      }

      &--box {
        bottom: 2rem;
        left: 2rem;
        max-width: 70ch;
        padding: 2rem 3rem;
        background-color: var(--primary);

        @include respond-below('tablet') {
          inset: 1rem;
          top: unset;
          padding: 2rem;
        }

        @include respond-below('phone') {
          padding: 1rem;
        }
      }
    }

    &-placement {
      &--center {
        .hero__content-buttons {
          justify-content: center !important;
        }
      }

      &--left {
        align-items: flex-start;
        justify-content: center;

        .kpb-text {
          text-align: left;
        }
      }
    }
  }

  .section-contact {
    background: var(--background);

    .kpb-section__container {
      background: white;

      @include respond-above('tablet') {
        padding: 4rem;
      }
    }
  }

  .section-residentials {
    .kpb-section__container {
      @include respond-below('phone') {
        padding: 0;
      }
    }
  }

  .section-info-image,
  .section-info {
    @include respond-below('phone') {
      padding: 2rem 1rem;
    }

    &__container {
      @include respond-below('phone') {
        padding: 0;
      }
    }
  }

  .kpb-blocks {
    .section-info {
      margin-bottom: 1em;

      &__content {
        align-items: center;
      }
    }

    .section-info-image {
      .kpb-section__container {
        @include respond-below('phone') {
          padding: 0;
        }
      }

      .info-image {
        &--frame {
          img {
            border: 3px solid var(--primary);
            padding: 0.4rem;
            background: transparent;
          }
        }

        &--round-edges {
          $radius: 3rem;

          img {
            border-radius: $radius;
          }

          .kvass-media-render-image__caption {
            border-radius: $radius;
          }

          @include respond-below('phone') {
            img {
              border-radius: calc(#{$radius} - 1rem);
            }

            .kvass-media-render-image__caption {
              border-radius: calc(#{$radius} - 1rem);
            }
          }
        }
      }
    }
  }
}
</style>
