export default function() {
  const get = path => this.$path(path, this.$store.state)
  const geti18n = (key, index = 1) => this.$tc('floorplan', index)

  return [
    {
      component: 'Section',
      props: {
        width: 'medium',
        id: 'section-header',
        class: 'section-header',
        style: 'opacity: 0; transform: translateX(-5rem); padding-top: 0; padding-bottom: 0;',
      },

      blocks: {
        default: [
          {
            component: 'Slider',
            props: {
              class: 'residential__cover',
            },
            data: get('Residential.item.media.cover'),
          },
        ],
      },
    },
    {
      component: 'Section',
      condition: get('Project.item.name'),
      props: {
        width: 'medium',
        id: 'section-info',
        class: 'section-info',
        style: 'padding-top:2rem;',
      },
      blocks: {
        default: {
          component: 'Grid',
          props: {
            columns: '1.5:1',
            gap: '2rem 5rem',
          },
          blocks: {
            default: [
              {
                component: 'Flex',
                props: {
                  flexDirection: 'column',
                },
                blocks: {
                  default: [
                    {
                      component: 'Text',
                      props: {
                        class: 'residential__description',
                      },
                      data: {
                        content: `<h1>${get('Residential.item.name')}</h1>${get(
                          'Residential.item.description',
                        ) || ''}`,
                      },
                    },
                    {
                      component: 'Openhouse',
                      props: {
                        class: 'residential__openhouse',
                        style: 'color: var(--body-color);',
                      },

                      data: {
                        content: get('Residential.item.openhouses'),
                      },
                      condition: () => {
                        if (!this.item) return false
                        if (get('Residential.item.openhouses.length')) return true
                        return !KvassConfig.get('siteSettings.hideNoOpenhouse')
                      },
                    },
                  ],
                },
              },
              {
                component: 'Summary',
                props: { class: 'residential__summary' },
                data: get('Residential.item'),
              },
            ],
          },
        },
      },
    },
    {
      condition: get('Residential.item.media.floorplan.length'),
      component: 'Section',
      props: {
        width: 'medium',
        id: 'section-info',
        class: 'section-floorplan',
      },
      blocks: {
        default: [
          {
            component: 'Text',
            props: {
              class: 'residential__description',
            },

            data: {
              content: `<h2>${geti18n('floorplan', 2)}</h2>`,
            },
          },
          {
            component: 'Gallery',
            props: {
              class: 'residential__floorplan',

              variant: 'grid',
              gap: '1rem',

              caption: {
                placement: 'inside',
                position: 'auto',
              },
            },

            data: {
              images: (get('Residential.item.media.floorplan') || []).map(i => {
                return {
                  ...i,
                  description: i.name,
                }
              }),
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      condition:
        get('Residential.item.shop.published') && !KvassConfig.get('siteSettings.hideShop'),
      props: {
        width: 'medium',
        id: 'section-shop-intro',
        class: 'section-shop-intro',
      },
      blocks: {
        default: [
          {
            component: 'ScrollAnchor',
            data: 'shop',
          },
          {
            component: 'ShopIntro',
            data: {
              residential: get('Residential.item'),
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      condition: [
        ...(get('Residential.item.media.attachments') || []),
        ...(get('Residential.item.project.media.attachments') || []),
      ].length,
      props: {
        width: 'medium',
        id: 'section-attachments',
        class: 'section-attachments',
      },
      blocks: {
        default: [
          {
            component: 'ScrollAnchor',
            data: 'attachments',
          },
          {
            component: 'Attachments',
            data: [
              ...(get('Residential.item.media.attachments') || []),
              ...(get('Residential.item.project.media.attachments') || []),
            ],
          },
        ],
      },
    },

    {
      component: 'Section',
      props: {
        width: 'medium',
        id: 'section-flatfinder',
        class: 'section-flatfinder',
        style: 'padding-bottom:1rem;',
      },
      blocks: {
        default: [
          {
            component: 'ScrollAnchor',
            data: 'flatfinder',
          },
          {
            component: 'Flatfinder',
            data: {
              flatfinders: get('Residential.item.project.flatfinders'),
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      props: {
        width: 'medium',
        id: 'section-residentials',
        class: 'section-residentials',
        style: 'padding-top:1rem',
      },
      blocks: {
        default: [
          {
            component: 'ScrollAnchor',
            data: 'residentials',
          },
          {
            component: 'Residentials',
            data: {
              project: get('Residential.item.project.id'),
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      props: {
        width: 'large',
        class: 'gallery',
      },
      blocks: {
        default: [
          {
            component: 'Masonry',
            variant: 'grid',

            data: {
              content: get('Residential.item.media.gallery'),
            },
          },
        ],
      },
    },
    {
      component: 'Section',
      condition: get('Residential.item.project.id'),
      props: {
        width: 'medium',
        id: 'section-contact',
        class: 'section-contact',
        style: 'background-color: var(--light-grey);',
      },
      blocks: {
        default: [
          {
            component: 'ScrollAnchor',
            data: 'lead',
          },
          {
            component: 'Grid',
            props: {
              gap: '2rem 8rem',
              columns: '1:2',
            },
            blocks: {
              default: [
                {
                  component: 'Roles',
                  props: {},
                  data: {
                    content: get('Residential.item.project.roles.salesman'),
                  },
                },
                {
                  component: 'Lead',
                  props: {
                    options: {
                      placeholders: {
                        'contact.name': this.$t('name'),
                        'contact.email': this.$t('email'),
                        'contact.phone': this.$t('phone'),
                        comment: this.$tc('message', 1),
                      },
                      fields: ['!title', '!contact.upsell'],
                      actionLabel: this.$t('send'),
                    },
                  },
                  data: {
                    project: get('Residential.item.project.id'),
                    reference: { onModel: 'Residential', ref: get('Residential.item.id') },
                  },
                  blocks: {
                    top: [
                      {
                        component: 'Text',
                        data: {
                          content: get('Residential.item.project.customFields.lead-top-content'),
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ]
}
