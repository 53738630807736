<template>
  <footer class="footer">
    <div class="footer__content">
      <FooterContent
        v-if="customFooterContent"
        :value="customFooterContent"
        class="footer__custom container"
      />

      <div class="footer__container container">
        <div>
          <img :src="logo" width="150" :alt="item.name" />
          <div class="footer__copyright">{{ year }} &copy; {{ item.name }}</div>
          <div class="footer__links">
            <a :href="`/api/legal/privacy/project/${item.id}`" target="_blank">
              {{ $t('privacyPolicy') }} / {{ $t('cookiePolicy') }}
            </a>
            <a href="#" @click.prevent="editConsents">{{ $t('editConsents') }}</a>
          </div>
        </div>

        <so-me-footer v-if="someCustomField && someCustomField.length" :value="someCustomField" />

        <div class="footer__menu">
          <strong class="footer__title">{{ $t('menu') }}</strong>
          <template v-for="(item, index) in navigation">
            <ActionComponent
              v-if="$path('action.name', item) !== $path('name', $route)"
              class="footer__menu-action"
              :class="{ 'footer__menu-action--empty': !item.action }"
              v-bind="item"
              :key="index"
            />
            <template v-if="item.items && item.items.length">
              <ActionComponent
                v-for="(nested, ni) in item.items"
                class="footer__menu-action footer__menu-action--indent"
                v-bind="nested"
                :key="index + '_' + ni"
              />
            </template>
          </template>
        </div>
      </div>
    </div>

    <div class="footer__bottom container">
      <span>
        {{ $t('pageMadeBy') }}
        <a
          class="footer__bottom-link"
          :href="`https://www.kvass.no?ref=${currentUrl}`"
          target="_blank"
        >
          Kvass
        </a>
      </span>
    </div>
  </footer>
</template>

<script>
import API from '@/api'
import { GetCustomField } from '@/utils'
import { mapState } from 'vuex'

import SoMeFooter from '@/components/SoMeFooter.vue'
import FooterContent from '@kvass/template-footer-content'
import ActionComponent from 'vue-elder-navigation/src/action'

export default {
  props: {
    navigation: Array,
    cookieManager: {
      type: Object,
      defualt: () => ({}),
    },
  },
  data() {
    return {
      year: new Date().getFullYear(),
    }
  },
  computed: {
    ...mapState('Root', ['item']),
    customFooterContent() {
      if (!this.item) return
      return GetCustomField('footer-content', this.item.customFields) || []
    },
    someCustomField() {
      if (!this.item) return
      const items = GetCustomField('social-media', this.item.customFields) || {}
      if (!Object.values(items).length) return

      return items
    },
    logo() {
      return API.logoInverted
    },
    currentUrl() {
      return encodeURIComponent(window.location.href)
    },
  },
  methods: {
    editConsents() {
      this.cookieManager.show()
    },
  },
  metaInfo(){
    return {
      script: [{ src: 'https://unpkg.com/@kvass/widgets@latest/dist/img-comparison-slider.js', type: 'module' }]
    }
  },
  components: {
    ActionComponent,
    FooterContent,
    SoMeFooter,
  },
}
</script>

<style lang="scss">
.footer {
  background-color: var(--primary);
  color: var(--primary-contrast);

  &__content {
    padding-block: 2rem;
  }

  &__bottom {
    padding-block: 2rem;

    &-link {
      border-bottom: 1px solid rgba($primary-contrast, 0.5);
      
      &:hover {
        border-color: var(--primary-contrast);
      }
    }
  }

  &__custom {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: unset;
    gap: 1rem;
    margin-bottom: 4rem;
  }

  &__container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 2rem;
  }

  &__copyright {
    margin-top: 2rem;

    a {
      font-weight: bold;
    }
  }

  &__menu {
    display: flex;
    flex-direction: column;

    a {
      & + a {
        margin-top: 0.5rem;
      }
    }
  }

  &__menu {
    &-action {
      padding: 0;
      background-color: transparent !important;
      color: inherit;
      font-weight: inherit;

      &--indent {
        margin-left: 0.5rem;
      }

      & + & {
        margin-top: 0.5rem;
      }
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  &__title {
    display: block;
    opacity: 0.5;
    margin-bottom: 1rem;
  }

  &__links {
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;
  }

  &__social-icons {
    a {
      margin-right: 0.8rem;
    }
  }

  .user-profile {
    display: none;
  }
}
</style>
