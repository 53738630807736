<template>
  <div class="footer__social">
    <strong class="footer__title">
      <span v-if="value.title" v-html="value.title"></span>
      <span v-else>{{ $t('followSoMeTitle') }}</span>
    </strong>
    <span v-html="value.description"></span>
    <div class="footer__social-icons">
      <span v-for="entry in soMeEntries" :key="entry.type">
        <a v-if="entry.link" :href="entry.link" target="_blank">
          <FontAwesomeIcon :icon="entry.icon" size="lg" />
        </a>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [Array, Object],
    },
  },
  computed: {
    soMeEntries() {
      return [
        {
          type: 'facebook',
          icon: ['fab', 'facebook'],
          link: this.value.facebook_url,
        },
        {
          type: 'intagram',
          icon: ['fab', 'instagram'],
          link: this.value.instagram_url,
        },
        {
          type: 'linkedin',
          icon: ['fab', 'linkedin'],
          link: this.value.linkedin_url,
        },
        {
          type: 'twitter',
          icon: ['fab', 'twitter'],
          link: this.value.twitter_url,
        },
      ]
    },
  },
}
</script>
