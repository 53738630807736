var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.value.length)?_c('div',{staticClass:"kvass-image-text"},_vm._l((_vm.value),function(item,index){return _c('div',{key:index,staticClass:"kvass-image-text__item",class:[
      `kvass-image-text__item-theme--${item.theme || 'none'}`,

      {
        'kvass-image-text__item--has-content': Boolean(item.description),
        'kvass-image-text__item--text-over': (item['image-settings'] || []).includes('text-over'),
      },
    ]},[(item.comparison)?_c('kvass-img-comparison-slider',{attrs:{"first-image":_vm.imageUrl(item.image[0]),"second-image":_vm.imageUrl(item.image[1])}}):(_vm.getImage(item))?_c(item.Component,_vm._b({tag:"component",staticClass:"kvass-image-text__image",class:[
        {
          'kvass-image-text__image--frame': (item['image-settings'] || []).includes('frame'),
          'kvass-image-text__image--round-edges': (item['image-settings'] || []).includes(
            'round-edges',
          ),
        },
        `kvass-image-text__image-type--${item.type}`,
      ],attrs:{"loop":"","value":{
        url: _vm.getImage(item).url,
        name: item.title,
        description: _vm.getImage(item).description,
      },"display-thumbnail":false}},'component',_vm.$attrs,false)):_vm._e(),_c('div',{staticClass:"kvass-image-text__content",class:{
        'kvass-image-text__content--text-over': (item['image-settings'] || []).includes(
          'text-over',
        ),
      }},[_c('div',{staticClass:"kvass-image-text__kicker"},[_vm._v(_vm._s(item.kicker))]),_c('div',{staticClass:"kvass-image-text__description",domProps:{"innerHTML":_vm._s(item.description)}})])],1)}),0):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }