<template>
  <div v-show="!hide" class="cta-banner">
    <Modal :show="showModal" clickaway @close="close" class="cta-banner__modal">
      <div class="cta-banner__modal-wrapper">
        <ButtonComponent
          class="cta-banner__modal-close"
          theme="primary"
          :icon="['fal', 'times']"
          @click="close"
          size="lg"
        />
        <div
          v-if="content"
          class="cta-banner__modal-content"
          v-html="content['modal-content']"
        ></div>
        <ButtonComponent
          class="cta-banner__modal-action"
          theme="primary"
          :label="$t('leadFormTitle')"
          @click="navigate"
          v-if="($path('settings', content) || []).includes('show-lead-button')"
        />
      </div>
    </Modal>
    <div v-if="content['trigger-content']" class="cta-banner__trigger">
      <div class="cta-banner__trigger-content" v-if="!decrease" @click="open">
        <ButtonComponent
          class="cta-banner__trigger-decrease"
          :icon="['fal', 'times']"
          @click.stop="decrease = true"
        />
        <div
          v-if="content"
          class="cta-banner__trigger-label"
          v-html="content['trigger-content']"
        ></div>
      </div>
      <ButtonComponent
        v-else
        class="cta-banner__trigger-increase"
        :icon="['far', 'arrow-left']"
        @click.stop="decrease = false"
      />
    </div>
  </div>
</template>

<script>
import { ModalComponent as Modal } from 'vue-elder-modal'
import { ScrollToAnchor } from '@/utils'

export default {
  data() {
    return {
      hide: false,
      decrease: false,
      showModal: false,
    }
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    content() {
      this.hide = (this.$path('data.content.settings') || ['hide']).includes('hide')
      let data = this.$path('data.content') || {}

      return {
        settings: data.settings || ['show-lead-button'],
        'modal-content':
          data['modal-content'] || '<h2>Kontakt oss for mer informasjon om prosjektet</h2>',
        'trigger-content':
          data['trigger-content'] || `<h2>Velkommen til ${this.$path('data.project.name')}</h2>`,
      }
    },
  },
  methods: {
    open() {
      this.showModal = true
    },
    close() {
      this.showModal = false
    },
    navigate() {
      ScrollToAnchor('lead')
      this.close()
    },
  },
  components: {
    Modal,
  },
}
</script>

<style lang="scss">
.cta-banner {
  &__modal {
    &-content {
      p {
        font-weight: normal;
      }
    }

    &-close {
      position: absolute !important;
      right: 0;
      top: 0;
      border-radius: 0 !important;

      .elder-button__icon {
        &:first-child:last-child {
          padding: 0.8em 1em;
          border-radius: 0;
        }

        svg {
          font-size: 1.3rem;
        }
      }
    }

    &-wrapper {
      padding: 5rem 2rem;
      background-color: white;
      width: 100%;
      max-width: 500px;
      position: relative;
      color: $dark;
    }

    &-action {
      margin-top: 2rem;
    }
  }

  &__trigger {
    $padding-y: 1.5rem;
    $padding-x: 2rem;

    position: fixed;
    right: 0;
    top: 40%;
    background-color: var(--primary);
    color: var(--primary-contrast);
    box-shadow: 0 10px 20px -10px black;

    display: flex;
    align-items: center;
    text-decoration: none;
    border-radius: 0 0 $border-radius $border-radius;
    z-index: get-layer('cta');
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    cursor: pointer;

    justify-content: center;

    @include respond-below('phone') {
      top: 15%;
    }

    &-content {
      padding: $padding-y $padding-x;
      transition: all 0.2s ease;

      &:hover {
        padding: calc(#{$padding-y} + 0.3rem) calc(#{$padding-x} + 0.3rem);
      }

      @include respond-below('phone') {
        padding: calc(#{$padding-y} - 0.3rem) calc(#{$padding-x} - 0.3rem);
      }
    }

    &-label {
      text-align: center;
      max-width: 150px;
      white-space: break-spaces;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      h2 {
        line-height: 1.7rem;
        font-size: clamp(0.8em, 1.2em, 2em);
      }

      p {
        font-size: clamp(0.5em, 0.8em, 1em);
      }

      h2,
      p {
        margin: 0;
      }
    }

    &-decrease {
      position: absolute !important;
      right: 0;
      top: 0;
      border: none !important;

      .elder-button {
        &__icon {
          padding: 0.5rem !important;

          svg {
            font-size: 1.3rem;
          }
        }
      }
    }

    &-increase {
      border: none !important;
      min-height: 150px;

      .elder-button {
        &__icon {
          display: flex;
          padding: 0.5rem !important;

          svg {
            font-size: 1.3rem;
          }
        }
      }

      @include respond-below('phone') {
        min-height: 60px;
      }
    }
  }
}
</style>
